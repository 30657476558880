<template>
  <div id="about" class="hightlighted-side-image container">
    <div class="row mb-4">
      <div class="col-12 col-md-7 text-left">
        <h2 class="mb-4 mt-5 font-family-krona"><span class="text-primary">DXboard</span> is the best way to manage your cryptos</h2>
        <p class="mb-5 text-gray">Do you have your cryptocurrencies distributed among multiple exchanges, wallets, etc...? Don't you know what your global balance is? <span class="text-primary">Dxboard</span> gives you the solution, manage your crypto assets without risk, search and add your cryptos and know their price and balance in real time with <span class="text-primary">no deposits</span>.</p>
        <div class="row">
          <div class="col-10 offset-1 col-md-12">
            <router-link class="btn btn-outline-primary mr-3 mr-md-5 btn-cta mb-4 mb-md-0 bg-body" :to="{ name: 'login'}">LOG IN</router-link>
            <router-link class="btn btn-primary btn-cta" :to="{ name: 'Dashboard'}">APP</router-link>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-block col-md-5 text-center">
        <img src="@/assets/movil.png" height="500" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  props: [
    'src',
    'alt'
  ],
  data() {
    return {
    };
  },
  created() {
    this.$emit('widgetLoaded');
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .hightlighted-side-image{
    margin-bottom:150px;
    
    h2{
      font-size: 38px;
      font-weight: normal;
    }
    p{
      font-size: 18px;
    }
    .btn{
      font-size: 12px;
    }

  }


</style>
